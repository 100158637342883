import moment from 'moment'

export default class TeamMngToken {
  id = null
  disabled = false
  tournamentTeamId = null
  tournamentTeamMemberId = null
  type = 'Player'
  validFrom = null
  validTo = null

  constructor (dto) {
    Object.assign(this, dto)
  }

  get fromUTC () {
    return this.validFrom && moment(this.validFrom)
  }

  get toUTC () {
    return this.validTo && moment(this.validTo)
  }

  get valid () {
    const vf = !this.fromUTC || moment().isSameOrAfter(this.fromUTC)
    const vt = !this.toUTC || moment().isSameOrBefore(this.toUTC)
    return vf && vt && !this.disabled
  }

  get fullAccess () {
    const x = this.type.toLowerCase()
    return !x.startsWith('roster') && x !== 'join-team'
  }

  get access () {
    const x = this.type.toLowerCase()
    return {
      full: !x.startsWith('roster') && x !== 'join-team',
      join: true,
      roster: x !== 'join-team'
    }
  }

  get divisionUpdate () {
    return this.divisionId && this.team && {
      id: this.divisionId,
      teams: [this.team]
    }
  }

  get storeDto () {
    return {
      teamId: this.tournamentTeamId,
      token: this.id.toLowerCase(),
      validTo: this.validTo
    }
  }
}
