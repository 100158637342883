<template>
  <v-card v-if="team">
    <v-toolbar color="color1 color1Text--text" prominent height="78">
      <div v-if="currentDivisionName" style="position: absolute;">{{currentDivisionName}}</div>
      <v-toolbar-title>{{team.name}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <qr-dialog></qr-dialog>
      <v-btn v-if="!page" color="color1Text" text fab small @click.stop="$emit('close-click')">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-alert type="info" :value="true" prominent text class="title mb-0" v-if="tournament.props.includes('fields')">
      You are playing at {{team.field}} Field
    </v-alert>
    <v-tabs
      v-model="activeTab"
      background-color="color2"
      slider-color="color3"
      show-arrows
      :dark="color2IsDark"
    >
      <v-tab
        :href="'#timeline'"
        class="color2Text--text"
      >
        Schedule
      </v-tab>
      <template v-if="!roster">
        <v-tab
          v-for="player in team.players"
          :key="`${player.playerProfileId}`"
          :href="`#${player.playerProfileId}`"
          class="color2Text--text"
        >
          {{player.name}}
        </v-tab>
      </template>
      <v-tab v-else
        href="#roster"
        key="roster"
        class="color2Text--text"
      >
        Roster
      </v-tab>
      <v-tab
        v-if="lineups"
        :href="'#lineups'"
        class="color2Text--text"
      >
        Lineups
      </v-tab>
    </v-tabs>
    <v-card-text class='pa-0'>
      <v-tabs-items v-model="activeTab">
        <v-tab-item value="timeline">
          <team-timeline-league :team="team" v-if="isLeague && false"></team-timeline-league>
          <team-timeline :team="team" v-else></team-timeline>
        </v-tab-item>
        <template v-if="!roster">
          <v-tab-item
            v-for="player in team.players"
            :key="player.playerProfileId"
            :value="`${player.playerProfileId}`"
          >
            <profile-home
              :playerProfileId="player.playerProfileId"
              :sbid="'AAU'"
              :playerName="player.name"
              :embeded="true"
              :key="player.playerProfileId"
            ></profile-home>
          </v-tab-item>
        </template>
        <v-tab-item v-else value="roster">
          <player-list :team="team"></player-list>
        </v-tab-item>
        <v-tab-item v-if="lineups" value="lineups">
          <lineup-display
            :tournament="tournament"
            :team="team"
            :division="currentDivision"
            :active="activeTab === 'lineups'"
          ></lineup-display>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { difference } from '@/helpers/SetFunctions'
import PlayerProfile from '@/classes/PlayerProfile'
import { firstBy } from 'thenby'
import TeamTimeline from '@/components/Team/Dialog/TeamTimeline'
import ProfileHome from '@/components/Player/ProfileHome'
const TeamTimelineLeague = () => import('@/components/Team/Dialog/TeamTimelineLeague')
const QrDialog = () => import('@/components/Utils/QRDialog')
const LineupDisplay = () => import('@/components/Team/Lineups/PublicLineupDisplay')
const PlayerList = () => import('@/components/Team/PlayerList')

export default {
  props: ['tab', 'team', 'page'],
  data () {
    return {
      activeTab: 'timeline',
      loading: false,
      displayRoster: [],
      rawRoster: []
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getTeam', 'color2IsDark', 'view', 'user']),
    roster () {
      return this.currentDivision && this.currentDivision.teamReg
    },
    hasTimeline () {
      return this.tournament.publicRounds.length !== 0
    },
    startTab () {
      return this.tab || 'timeline'
    },
    currentDivision () {
      if (!this.team || this.team.isDeleted) return null

      const division = this.tournament.divisions.find((d) => {
        return d.teams.find((t) => {
          return t.id === this.team.id
        })
      })
      return division
    },
    lineups () {
      return this.currentDivision && this.currentDivision.lineups && !this.currentDivision.lineupLock
    },
    isLeague () {
      return this.tournament && this.tournament.isLeague
    },
    currentDivisionName () {
      if (!this.currentDivision) return 'Withdrawn from the tournament'
      return `${this.currentDivision.name}${this.team.waitlist ? ' - WAITLIST' : ''}`
    },
    fullyLoaded () {
      const loaded = new Set(this.displayRoster.map(m => m.id))
      const teamPlayers = new Set(this.team.players.map(m => m.playerProfileId))
      const diff = difference(loaded, teamPlayers)
      return diff.size === 0
    }
  },
  methods: {
    getPlayers () {
      if (!this.fullyLoaded) return
      this.loading = true
      this.$VBL.get.playerList(null, { ids: this.team.players.map(m => m.playerProfileId) })
        .then(r => {
          this.rawRoster = [...r.data.players]
          this.displayRoster = r.data.players.map(m => new PlayerProfile(null, m))
          this.displayRoster.sort(firstBy('lastName').thenBy('firstName'))
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    startTab: function (n, o) {
      this.activeTab = `${this.startTab}`
    },
    activeTab: function (v) {
      console.log('here')
      v && v === 'roster' && this.getPlayers()
    }
  },
  created () {
    this.activeTab = `${this.startTab}`
  },
  components: {
    ProfileHome,
    TeamTimeline,
    TeamTimelineLeague,
    QrDialog,
    LineupDisplay,
    PlayerList
  }
}
</script>

<style>

</style>
