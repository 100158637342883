<template>
  <v-card height="100%" light>
    <v-toolbar color="color1 color1Text--text">
      <v-toolbar-title>Team Manager</v-toolbar-title>
      <v-spacer></v-spacer>
      <team-access
        :team="team"
        :tokenStatus="tokenStatus"
        v-if="access.admin"
        color="color1Text"
      ></team-access>
      <qr-dialog color="color1Text"></qr-dialog>
      <!-- TEAM CHECK IN-->
      <checkin-dialog
        v-if="team"
        :tournament="tournament"
        :team="team"
        :division="currentDivision"
        :tokenStatus="tokenStatus"
      ></checkin-dialog>
    </v-toolbar>

    <TeamBalanceDrawer
      v-if="checkBalance"
      ref="balance"
      :team="team"
      :credentials="credentials"
      :access="access"
      @update="onBalanceUpdate"
    />

    <v-tabs
      v-model="activeTab"
      background-color="color2"
      slider-color="color3"
      touchless
      show-arrows
    >
      <v-tab href="#schedule" class="color2Text--text">Schedule</v-tab>
      <v-tab href="#waitlist" class="color2Text--text" v-if="team && team.waitlist">Status</v-tab>
      <v-tab href="#division" class="color2Text--text" v-if="access.admin">Division</v-tab>
      <v-tab href="#partner" class="color2Text--text">{{currentDivision && currentDivision.numOfPlayers === 2 ? 'Partner' : 'Roster'}}</v-tab>
      <v-tab href="#lineups" class="color2Text--text"  v-if="lineups && (user && user.vbl)">Line Ups</v-tab>
      <v-tab href="#withdraw" class="color2Text--text" v-if="access.admin">Withdraw</v-tab>
      <v-tab href="#notes" class="color2Text--text">Info</v-tab>
      <v-tab href="#registration" class="color2Text--text" v-if="access.admin">Registration</v-tab>
      <v-tab href="#paymentHistory" class="color2Text--text" v-if="access.td">Payment History</v-tab>
      <v-tab href="#tags" class="color2Text--text" v-if="access.admin">Team Tags</v-tab>
    </v-tabs>
    <loading-bar :value="loadingRegData"></loading-bar>
    <v-container fluid v-if="team">
      <v-row dense v-if="tournament.props.includes('fields') && currentDivision">
        <v-col cols="12" class="title pa-0">
          <v-alert type="info" :value="true" prominent text class="title mb-0">
            This team is playing at {{team.field}} Field
          </v-alert>
          <field-edit
            v-if="tournament && team && currentDivision && access.td"
            :tournament="tournament"
            :team="team"
            :currentDivision="currentDivision"
          ></field-edit>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <strong>Tournament:</strong> {{tournament.name}}
        </v-col>
      </v-row>
      <v-row dense v-if="currentDivision && (currentDivision.numOfPlayers > 2 || currentDivision.useTeamNames || (user && user.vbl))">
        <v-col cols="12">
          <strong>Team:</strong> {{team.name}}
          <!-- TEAM NAME EDIT -->
            <v-dialog
              v-if="!currentDivision.noTeamNames && access.admin"
              v-model="nameDialog"
              :persistent="teamName !== team.name"
              scrollable
              max-width="500px"
              transition="dialog-transition"
            >
              <template v-slot:activator="{on}">
                <v-btn
                  v-on="on"
                  color="color3"
                  text x-small
                  icon
                >
                  <v-icon small>fas fa-pencil</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-toolbar color="color1 color1Text--text">
                  <v-toolbar-title>Team Name Update</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="color2 color2Text--text" small fab @click.stop="nameDialog = false">
                    <v-icon>fas fa-times</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    label="Team Name"
                    v-model="teamName"
                    color="color3"
                  ></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="success"
                    small fab
                    :disabled="teamName === team.name"
                    :loading="savingName"
                    @click.stop="updateName"
                  >
                    <v-icon>fas fa-save</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-col>
      </v-row>
      <v-row dense v-if="!lineups">
        <v-col cols="12">
          <strong>Players:</strong> <team-display :team="team"></team-display>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <strong>Current Division:</strong> {{currentDivisionName}}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">

          <v-tabs-items v-model="activeTab">

            <v-tab-item value="schedule" v-if="team">
              <team-timeline-league :team="team" v-if="isLeague && false"></team-timeline-league>
              <team-timeline :team="team" v-else></team-timeline>
            </v-tab-item>

            <v-tab-item value="waitlist" v-if="team && team.waitlist">
              <waitlist-manage
                :tournament="tournament"
                :team="team"
                :currentDivision="currentDivision"
              ></waitlist-manage>
            </v-tab-item>

            <v-tab-item value="division" v-if="access.admin">
              <division-change
                ref="divisionComponent"
                :tournament="tournament"
                :team="team"
                :currentDivision="currentDivision"
                @division-changed="onDivisionChanged"
                @request-submitted="onRequestSubmitted"
              >
              </division-change>
            </v-tab-item>

            <v-tab-item value="partner">
              <partner-change
                :key="`${activeTab}-partner`"
                ref="partnerComponent"
                :tournament="tournament"
                :team="team"
                :currentDivision="currentDivision"
                :active="activeTab === 'partner'"
                :access="access"
                @complete="onPartnerChangeComplete"
                @request-submitted="onRequestSubmitted"
              ></partner-change>
              <v-card class="mt-6">
                <v-toolbar dense color="color2 color2Text--text">
                  Current Players
                </v-toolbar>
                <v-card-text class="pa-0">
                  <player-list
                    :team="team"
                    :active="activeTab === 'partner'"
                    :access="access"
                  ></player-list>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="lineups" v-if="lineups && (user && user.vbl)">
              <lineup-manager
                :tournament="tournament"
                :team="team"
                :division="currentDivision"
                :credentials="credentials"
                :tokenStatus="tokenStatus"
                :active="activeTab === 'lineups'"
                :showButtons="true"
              ></lineup-manager>
            </v-tab-item>

            <v-tab-item value="withdraw" v-if="access.admin">
              <team-withdraw
                ref="withdrawComponent"
                :tournament="tournament"
                :team="team"
                @team-deleted="onTeamDeleted"
                @team-withdrawand-refunded="onWithdrawAndRefund"
                @request-submitted="onRequestSubmitted"
              ></team-withdraw>
            </v-tab-item>

            <v-tab-item value="refund" v-if="access.td">
              <v-container fluid>
                <v-row dense>
                  <v-col class="text-center" >
                    <h3>Please use the payment history tab</h3>
                    <v-btn color="color3 color3Text--text" @click.stop="activeTab = 'paymentHistory'">
                      Payment History
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>

            </v-tab-item>

            <v-tab-item value="notes">
              <team-notes
                :notes="team.notes"
                :loading="loadingNotes"
                @refresh-click="getNotes"
              ></team-notes>
            </v-tab-item>

            <v-tab-item value="registration" v-if="access.admin">
              <registration-data
                v-if="activeTab === 'registration'"
                :tournament="tournament"
                :team="team"
                :division="currentDivision"
                :loading="loadingRegData"
              >
              </registration-data>
            </v-tab-item>

            <v-tab-item value="paymentHistory" v-if="access.td">
              <template v-if="team && team.paymentStatus && team.paymentStatus.length > 0">
                <v-card v-for="item in team.paymentStatus" :key="item.id" class="mb-3">
                  <payment-history
                    :bagItemId="item.id"
                    :credentials="{ id: team && team.registrationData ? team.registrationData.orderId : null, email: null }"
                    :active="activeTab === 'paymentHistory'"
                    :teamId="team && team.id"
                    @update="onPaymentUpdate"
                    ref="payH"
                  ></payment-history>
                </v-card>
              </template>
              <v-card v-else>
                <payment-history
                  :bagItemId="team && team.paymentStatus ? team.paymentStatus.id : null"
                  :credentials="{ id: team && team.registrationData ? team.registrationData.orderId : null, email: null }"
                  :active="activeTab === 'paymentHistory'"
                  :teamId="team && team.id"
                  @update="onPaymentUpdate"
                  :isAdmin="access.td"
                  ref="payH"
                ></payment-history>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tags" v-if="access.admin">
              <tag-editor :team="team"></tag-editor>
            </v-tab-item>

          </v-tabs-items>
        </v-col>
      </v-row>
      <v-row v-if="user && user.id === 1" dense>
        <v-col cols="12" class="justify-end d-flex" v-if="access.td && merged">
          <v-btn
            color="success"
            text
            @click.stop="unMerge = true"
          >Undo Team Merge</v-btn>
          <vbl-confirm-dialog
            :ask="unMerge"
            @deny="unMerge = false"
            @confirm="doUnMerge"
            :loading="loadingUnMerge"
          ></vbl-confirm-dialog>
        </v-col>
        <v-col cols="12" class="justify-end d-flex">
          <v-switch
            label="Drop from points"
            v-model="team.drop"
            readonly
            @click.stop="toggleDrop"
            dense
            class="shrink"
            :loading="dload"
            color="success"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row justify="center"  align="end">
        <v-btn
          v-if="dialog"
          color="color3 white--text"
        >Close</v-btn>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row dense>
        <v-col cols="12">
          <v-progress-linear :indeterminate="true" color="color3"></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      vertical
      v-model="snackbar"
      :timeout="3000"
    >
      {{snackbarText}}
      <v-btn text color="color3" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
const DivisionChange = () => import('@/components/Team/DivisionChange.vue')
const PartnerChange = () => import('@/components/Team/PartnerChange.vue')
const Withdraw = () => import('@/components/Team/Withdraw.vue')
const Notes = () => import('@/components/Team/Notes.vue')
const RegistrationData = () => import('@/components/Team/RegistrationData.vue')
const TeamDisplay = () => import('@/components/Team/TeamDisplay.vue')
const WaitlistManage = () => import('@/components/Team/Waitlist')
const CheckinDialog = () => import('@/components/Team/CheckinDialog')
const PaymentHistory = () => import('@/components/Cart/PaymentHistory')
const LineupManager = () => import('@/components/Team/Lineups/LineupManager')
const FieldEdit = () => import('@/components/Tournament/Fields/FieldEdit')
const QrDialog = () => import('@/components/Utils/QRDialog')
const TeamAccess = () => import('@/components/Team/TeamAccessDialog')
const TeamTimeline = () => import('@/components/Team/Dialog/TeamTimeline')
const TeamTimelineLeague = () => import('@/components/Team/Dialog/TeamTimelineLeague')
const PlayerList = () => import('@/components/Team/PlayerList')
const TagEditor = () => import('@/components/Team/TagEditor')
const TeamBalanceDrawer = () => import('@/components/Team/TeamBalanceDrawer')

export default {
  props: ['tournament', 'team', 'startTab', 'dialog', 'tokenStatus'],
  data () {
    return {
      activeTab: 'partner',
      snackbar: false,
      snackbarText: null,
      loadingNotes: false,
      loadingPayment: false,
      loadingRegData: false,
      checkingIn: false,
      noShow: false,
      teamName: null,
      nameDialog: false,
      savingName: false,
      dload: false,
      unMerge: false,
      loadingUnMerge: false
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView', 'getTeamToken']),
    access () {
      return {
        vbl: this.user && this.user.vbl,
        td: this.userIsAdmin,
        teamAdmin: !!(this.tokenStatus && this.tokenStatus.access.full),
        get admin () {
          return this.td || this.teamAdmin || this.vbl
        },
        roster: !!(this.admin || (this.tokenStatus && this.tokenStatus.access.roster)),
        join: !!(this.admin || (this.tokenStatus && this.tokenStatus.access.join)),
        joinOnly: this.tokenStatus && this.tokenStatus.type === 'join-team'
      }
    },
    userIsAdmin () {
      return this.user && !this.publicView && this.user.isTournamentAdmin(this.tournament)
    },
    editTeamname () {
      return this.currentDivision && this.currentDivision.numOfPlayers > 2
    },
    currentDivision () {
      if (!this.team || this.team.isDeleted) return null

      const division = this.tournament.divisions.find((d) => {
        return d.teams.find((t) => {
          return t.id === this.team.id
        })
      })
      return division
    },
    currentDivisionName () {
      if (!this.currentDivision) return 'Withdrawn from the tournament'
      return `${this.currentDivision.name}${this.team.waitlist && !this.currentDivision.acceptanceOnly ? ' - WAITLIST' : ''}`
    },
    teamToken () {
      return this.getTeamToken(this.team.id, this.$route)
    },
    lineups () {
      return this.currentDivision && this.currentDivision.lineups
    },
    order () {
      return this.$store.getters.order
    },
    credentials () {
      return {
        orderId: this.order ? this.order.id : this.team && this.team.registrationData ? this.team.registrationData.orderId : null,
        email: this.order ? this.order.emailReceiptTo : null
      }
    },
    isLeague () {
      return this.tournament && this.tournament.isLeague
    },
    merged () {
      return this.team.tags.includes('merge-result')
    },
    qtab () {
      return this.$route.query.tab
    },
    checkBalance () {
      return (this.team && !this.team.waitlist && !this.team.isDeleted) && (this.credentials && this.credentials.orderId)
    }
  },
  methods: {
    toggleDrop () {
      this.dload = true
      this.$VBL.team.drop(this.team.id, this.team.drop)
        .catch(e => { console.log(e.response) })
        .finally(() => { this.dload = false })
    },
    onTeamDeleted () {
      this.openSnackbar(`${this.team.name} has been removed from the tournament`)
      this.activeTab = this.$refs.refundComponent && this.$refs.refundComponent.refundAvailable ? 'refund' : 'notes'
    },
    onDivisionChanged (data) {
      this.openSnackbar(`${this.team.name} has been moved to ${this.currentDivisionName}`)
      this.activeTab = 'notes'
    },
    onRefundComplete () {
      this.openSnackbar('The refund has been issued.')
      this.getNotes()
      this.getPaymentStatus()
      this.activeTab = 'notes'
    },
    onWithdrawAndRefund () {
      this.openSnackbar(`${this.team.name} has been removed from the tournament and a refund has been issued.`)
      this.getNotes()
      this.getPaymentStatus()
      this.activeTab = 'notes'
    },
    onPartnerChangeComplete () {
      this.getRegData()
      if (this.dialog) {
        this.$emit('partner-change-complete')
        return
      }
      this.openSnackbar('Partner change complete.')
      if (!this.lineups) this.activeTab = 'notes'
    },
    onRequestSubmitted () {
      this.openSnackbar('Your request has been sent to the tournament director.')
      this.getNotes()
      this.activeTab = 'notes'
    },
    onPaymentUpdate () {
      this.init(true)
      if (this.$refs.balance) {
        this.$refs.balance.update()
      }
    },
    onBalanceUpdate () {
      this.init(true)
      this.getPaymentStatus()
      if (this.$refs.balance) {
        this.$refs.balance.update()
      }
      if (this.$refs.payH) {
        this.$refs.payH[0].getBagItem(true)
      }
    },
    openSnackbar (text) {
      this.snackbarText = text
      this.snackbar = true
    },
    getNotes () {
      this.loadingNotes = true
      this.$VBL.team.getNotes(this.team.id)
        .then((response) => {
          this.team.notes = response.data
          this.loadingNotes = false
        })
        .catch((error) => {
          console.log(error.response)
          this.loadingNotes = false
        })
    },
    getPaymentStatus () {
      this.loadingPayment = true
      this.$VBL.cart.getRegistrationPaymentStatus(this.team.id)
        .then((response) => {
          this.team.paymentStatus = response.data
          this.loadingPayment = false
        })
        .catch((error) => {
          console.log(error.response)
          this.loadingPayment = false
        })
    },
    getRegData () {
      this.loadingRegData = true
      this.$VBL.team.getRegData(this.team.id)
        .then((response) => {
          this.team.registrationData = response.data
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.loadingRegData = false
        })
    },
    init (force) {
      if (!this.team) return
      this.teamName = this.team.name
      if (!this.team.notes || force) this.getNotes()
      if (!this.team.paymentStatus || force) this.getPaymentStatus()
      if (!this.team.registrationData || !this.team.registrationData.players || !this.team.registrationData.players.length || force) this.getRegData()
      if (this.team.waitlist) this.activeTab = 'waitlist'
    },
    checkin () {
      this.checkingIn = true
      this.$VBL.team.checkIn(this.team.id, true, this.teamToken)
        .then(() => {
          this.getNotes()
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.checkingIn = false
        })
    },
    updateName () {
      this.savingName = true
      this.$VBL.team.updateName({
        id: this.team.id,
        name: this.teamName,
        token: this.teamToken
      })
        .then(r => {
          this.team.name = this.teamName
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.savingName = false
          this.nameDialog = false
        })
    },
    doUnMerge () {
      this.loadingUnMerge = true
      this.$VBL.team.undoMerge(this.team.id)
        .then(r => {
          this.openSnackbar('Merge undone.')
          this.getNotes()
          this.getPaymentStatus()
          this.activeTab = 'notes'
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loadingUnMerge = false
          this.unMerge = false
        })
    },
    saveState (tab) {
      const query = {}
      Object.assign(query, this.$route.query)
      if (tab !== (query.tab)) {
        query.tab = tab

        this.$router.push({
          query
        })
      }
    }
  },
  watch: {
    activeTab: function (newTab, oldTab) {
      const component = this.$refs[`${oldTab}Component`]
      if (component && component.reset) component.reset()
      this.saveState(newTab)
    },
    qtab: function (val) {
      if (val && this.activeTab !== val) this.activeTab = val
    },
    team: function (val) {
      if (val) this.init()
    },
    nameDialog: function (val) {
      this.teamName = this.team.name
    }
  },
  mounted () {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab
    } else if (this.startTab) {
      this.activeTab = this.startTab
    }
    this.init()
  },
  components: {
    DivisionChange,
    PartnerChange,
    'team-withdraw': Withdraw,
    'team-notes': Notes,
    TeamDisplay,
    RegistrationData,
    WaitlistManage,
    CheckinDialog,
    PaymentHistory,
    LineupManager,
    FieldEdit,
    QrDialog,
    TeamAccess,
    TeamTimeline,
    TeamTimelineLeague,
    PlayerList,
    TagEditor,
    TeamBalanceDrawer
  }
}
</script>

<style>

</style>
