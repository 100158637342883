<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- BREADCRUMBS -->
    <v-row dense>
      <v-col cols="12">
        <vbl-breadcrumbs :crumbs="breadcrumbs"></vbl-breadcrumbs>
      </v-col>
    </v-row>
    <!-- MAIN CONTENT -->
    <v-row dense  v-if="loading">
      <v-col cols="12">
        <v-card>
          <v-card-text class="text-center">
            <h3>Please wait, while I do some looking up</h3>
            <v-progress-circular indeterminate v-bind:size="50" color="color3"></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <team-manager
      v-else-if="view === 'manager'"
      :tournament="tournament"
      :team="team"
      :tokenStatus="tokenStatus"
    ></team-manager>
    <v-row dense v-else-if="!!team">
      <v-col class="12">
        <team-home v-if="team" :team="team" :page="true" :tab="playerId ? `${playerId}` : null"></team-home>
      </v-col>
    </v-row>
    <v-row dense v-else>
      <v-col cols="12">
        <message-card>
          <h2 class="ma-12 text-center">
            <v-avatar color="red">
              <v-icon color="white">fas fa-eye-slash</v-icon>
            </v-avatar>
            <br class="hidden-md-and-up">
            The team you are looking for can't be found.
          </h2>
        </message-card>
      </v-col>
    </v-row>

    <v-fab-transition>
      <v-btn
        color="color3 color3Text--text"
        :key="view"
        fab
        fixed bottom right
        v-if="(admin || validToken) && !loading"
        @click.stop="toggle = !toggle"
      >
        <v-icon>{{view === 'manager' ? 'fas fa-clipboard-list' : 'fas fa-user-shield'}}</v-icon>
      </v-btn>

    </v-fab-transition>
  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import TeamManager from '@/components/Team/TeamManager'
import TeamHome from '@//components/Team/PublicHome'
import * as mutations from '@/store/MutationTypes'
import { mapGetters } from 'vuex'
import TeamMngToken from '@/classes/TeamMngToken'

export default {
  mixins: [RouteMixin],
  props: ['playerId'],
  data () {
    return {
      error: null,
      loading: false,
      toggle: false,
      tokenStatus: null
    }
  },
  computed: {
    ...mapGetters(['tournament', 'admin', 'publicView', 'tokens', 'user']),
    team () {
      return this.tournament.teams.find(t => t.id === this.teamId)
    },
    view () {
      let r = ''
      let t = ''
      if ((this.admin && !this.publicView) || this.validToken) {
        r = 'manager'
        t = 'timeline'
      } else {
        r = 'timeline'
        t = 'manager'
      }

      return this.toggle ? t : r
    },
    token () {
      return this.qToken || this.storedToken
    },
    qToken () {
      return this.$route.query.token
    },
    storedToken () {
      const a = this.tokens.filter(f => f.teamId === this.teamId)
      return a.length > 0 ? a[0].token : false
    },
    breadcrumbs () {
      return [
        {
          text: 'Tournament Home',
          to: { name: 'tournament-brochure' },
          exact: true,
          disabled: false
        },
        {
          text: this.team ? this.team.name : 'Team Manager',
          disabled: true
        }
      ]
    },
    validToken () {
      return this.tokenStatus && this.tokenStatus.id && this.tokenStatus.valid
    },
    checkedIn () {
      return this.team && this.team.checkedIn
    }
  },
  methods: {
    checkToken (checkUser) {
      if (!(this.token || checkUser)) return
      this.loading = true
      var t = checkUser ? 'user-check' : this.token
      this.$VBL.team.getToken(this.teamId, t)
        .then(r => {
          this.tokenStatus = new TeamMngToken(r.data)
          this.$store.commit(mutations.UPDATE_TOKEN, this.tokenStatus)
          if (!this.team && this.tokenStatus.divisionUpdate) {
            this.$store.commit(mutations.UPDATE_DIVISION, this.tokenStatus.divisionUpdate)
          }
          if (!this.tokenStatus.valid && this.qToken) {
            const q = JSON.parse(JSON.stringify(this.$route.query))
            delete q.token
            this.$router.replace({ query: q })
          } else if (!this.qToken || this.qToken !== this.tokenStatus.id) {
            const q = JSON.parse(JSON.stringify(this.$route.query))
            q.token = this.tokenStatus.id
            this.$router.replace({ query: q })
          }
        })
        .catch(e => {
          this.error = e.response.data
        })
        .finally(() => {
          this.loading = false
          console.log(this.tokens)
          console.log(this.tokenStatus)
        })
    },
    toggleView () {
      this.$store.commit(mutations.TOGGLE_VIEW)
    }
  },
  watch: {
    checkedIn: function (n, o) {
      if (n && !o && this.view === 'manager') {
        this.toggle = !this.toggle
      }
    },
    teamId: 'checkToken'
  },
  components: {
    TeamManager,
    TeamHome
  },
  mounted () {
    if (this.token) {
      this.checkToken()
    } else if (this.user && !this.admin) {
      this.checkToken(this.user.id)
    }
  }
}
</script>
